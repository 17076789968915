.section__about-dvd {

}

.about-dvd {
  &__content {
    p {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 25px;
    }
  }

  &__image {
    margin-bottom: 20px;

    @include media-breakpoint-only(lg) {
      max-height: 190px;
    }

    @include media-breakpoint-down(lg) {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}
