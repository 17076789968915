.section__buy-now {
  small {
    display: block;

    @include media-breakpoint-up(xl) {
      padding-right: 30px;
    }

    @include media-breakpoint-only(lg) {
      padding-right: 35px;
    }

    @extend %helvetica-med;
  }
}

.buy-now {
  &__labels {
    li {
      text-transform: uppercase;

      span {
        background-color: $yellow;
        display: inline-block;
        padding: 5px 10px;
        font-size: 27px;

        @extend %helvetica-cn-bold;
      }

      &:nth-child(even) {
        span {
          background-color: $orange;
        }
      }
    }
  }

  &__title {
    cursor: pointer;
    font-size: 45px;
    margin: 0 0 10px;
    text-transform: uppercase;
    line-height: 1;

    @include media-breakpoint-down(md) {
      font-size: 36px;
    }

    &:hover {
      text-decoration: underline;
    }

    @extend %helvetica-cn-bold;
  }

  &__price {
    background: transparent url("../images/price-splash.png") no-repeat center center;
    background-size: contain;
    display: flex;
    float: right;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 117px;
    font-size: 48px;
    color: $white;
    flex-shrink: 0;
    cursor: pointer;
    padding-bottom: 5px;

    @extend %helvetica-cn-bold;

    &-sign {
      font-family: $font-family-base;
      display: inline-block;
      margin-right: 10px;
      font-size: 34px;
      align-self: center;
      padding-top: 10px;
    }
  }

  &__include {
    font-size: 24px;

    @extend %helvetica-cn-bold;
  }

  &__shipping {
    font-size: 18px;
    margin-top: 15px;
  }

  &__btn {
    margin-top: 20px;
  }

  &__purchase {
    @include media-breakpoint-up(lg) {
      &::after {
        content: '';
        display: block;
        background: transparent url("../images/dvd-box.png") no-repeat center center;
        width: 280px;
        height: 345px;
        position: absolute;
        right: 0;
        left: calc(100% + 30px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
