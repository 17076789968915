.content-info {
  text-align: center;
  font-size: $small-font-size;

  .star {
    background: transparent url("../images/mini-star.png") no-repeat center center;
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 4px;
    width: 7px;
    height: 7px;

    @include media-breakpoint-down(sm) {
      display: block;
      margin: 5px auto;
    }
  }

  hr {
    border-top: 1px solid #8d7c72;
    border-bottom: 1px solid $white;
    margin-bottom: 10px;
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  &__copyright {
    display: inline-block;

    p {
      padding-left: 10px;
      padding-right: 10px;

      @include media-breakpoint-only(lg) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
