// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$grid-gutter-width: 20px;

$font-family-base: 'HelveticaNeueLT Std', sans-serif;

$font-size-base: 1rem;
$font-size-sm: 0.875rem;

$line-height-base: 1.2;

$small-font-size: $font-size-sm;

$yellow: #ffec31;
$orange: #f68b1f;
$red: #ed1c24;

$link-color: #000;
$body-color: #331a1e;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1050px,
  xl: 1360px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 990px,
  xl: 1300px
);

$spacer: 10px;
$spacers: (
  1: 10px,
  2: 20px,
  3: 30px,
  4: 40px,
  5: 50px,
  6: 60px
);

$border-radius: 0;
$border-radius-base: 0;
