body {
  position: relative;
  padding-bottom: 25px;

  &:not(#tinymce) {
    background: transparent url("../images/bg-image.png") repeat left top;

    &::before,
    &::after {
      background: transparent url("../images/film-ribbon.png") repeat-x left top;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 17px;
      z-index: 10;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
    transform: rotate(180deg);
  }
}

body,
html {
  overflow-x: hidden;
}

.overlay-grain {
  position: relative;

  &::after {
    background: transparent url("../images/bg-image.png") repeat left top;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
