.section__facts {
  &--bg {
    .facts__container {
      @include media-breakpoint-up(xl) {
        background: transparent url("../images/bg-image-2.png") repeat left top;
      }
    }

    @include media-breakpoint-down(lg) {
      background: transparent url("../images/bg-image-2.png") repeat left top;
    }
  }
}

.facts {
  &__container {
    padding-bottom: 35px;
    padding-top: 35px;

    &--1 {
      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }
    }
  }

  &__video {
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    @include media-breakpoint-only(md) {
      margin-bottom: 30px;
    }
  }

  &__list {
    margin-bottom: 0;

    &--1 {
      @include media-breakpoint-up(xl) {
        padding-right: 15px;
      }

      @include media-breakpoint-only(lg) {
        padding-right: 30px;
      }
    }

    &--2 {
      @include media-breakpoint-up(lg) {
        padding-right: 20px;
      }
    }

    &--3 {
      @include media-breakpoint-up(xl) {
        padding-right: 47px;
      }

      @include media-breakpoint-only(lg) {
        padding-right: 51px;
      }
    }

    li {
      font-size: 27px;
      line-height: 1;
      position: relative;

      @extend %helvetica-cn-bold;

      &::before {
        background: transparent url("../images/star.png") no-repeat center center;
        content: '';
        display: block;
        position: absolute;
        right: calc(100% + 10px);
        top: 3px;
        width: 20px;
        height: 20px;

        @include media-breakpoint-down(sm) {
          position: relative;
          right: auto;
          top: auto;
          margin-right: 10px;
          display: inline-block;
          vertical-align: top;
          margin-top: 3px;
        }
      }

      & + li {
        margin-top: 20px;
      }
    }
  }

  &__watch-dvd {
    h2 {
      font-size: 27px;
      margin: 10px 0 0 0;
    }

    @extend %helvetica-cn-bold;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    a {
      display: block;
    }
  }

  &__image {
    @include media-breakpoint-down(sm) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      height: auto;
    }

    &--woman {
      @include media-breakpoint-up(lg) {
        margin-left: -20px;
      }
    }
  }

  &__column-1 {
    @include media-breakpoint-only(md) {
      display: flex;
      flex-direction: row;
    }
  }

  &__column-2 {
    @include media-breakpoint-only(md) {
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      .facts__list {
        padding-left: 50px;
      }
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      img {
        order: 2;
        margin-top: 20px;
      }

      .facts__list {
        order: 1;
      }
    }
  }

  &__column-3 {
    @include media-breakpoint-only(md) {
      display: flex;
      flex-direction: row;

      .facts__list {
        margin-top: 20px;
      }
    }
  }
}
