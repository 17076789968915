.section__about {
  @include media-breakpoint-down(lg) {
    background: transparent url("../images/harry-egipt.jpg") no-repeat center top;
    background-size: cover;
  }
}

.about {
  &__container {
    color: $white;

    @include media-breakpoint-up(xl) {
      background: transparent url("../images/harry-egipt.jpg") no-repeat center top;
      background-size: cover;
    }

    @include media-breakpoint-down(sm) {
      background-position: 60% top;
    }
  }

  &__intro {
    font-size: 29px;
    line-height: 1.2;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-size: 63px;
    margin-bottom: 30px;
  }

  &__intro,
  &__title {
    @extend %helvetica-cn-bold;
    text-shadow: 1px 1px 1px #000000;
  }

  &__description {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include media-breakpoint-only(lg) {
      padding-right: 15px;
    }

    p {
      margin-bottom: 24px;
    }

    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__facebook {
    color: $white;
    font-weight: bold;
    font-size: $small-font-size;

    &:hover {
      color: $white;
    }

    &::before {
      background: transparent url("../images/facebook-icon.png") no-repeat center center;
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 29px;
      height: 29px;
      margin-right: 15px;
    }
  }
}
