
@font-face {
    font-family:'HelveticaNeueLT Std';
    src: url('../fonts/HelveticaNeueLTStd-Roman.eot');
    src: url('../fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'HelveticaNeueLT Std';
    src: url('../fonts/HelveticaNeueLTStd-Bd.eot');
    src: url('../fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStd-Bd.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'HelveticaNeueLT Std Cn';
    src: url('../fonts/HelveticaNeueLTStd-BdCn.eot');
    src: url('../fonts/HelveticaNeueLTStd-BdCn.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStd-BdCn.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStd-BdCn.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStd-BdCn.svg#HelveticaNeueLTStd-BdCn') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: condensed;
    unicode-range: U+0020-00FE;
}

%helvetica-cn-bold {
    font-family:'HelveticaNeueLT Std Cn';
}

%helvetica-med {
    font-family:'HelveticaNeueLT Std Med';
}

@font-face {
    font-family:'HelveticaNeueLT Std Med';
    src: url('../fonts/HelveticaNeueLTStd-Md.eot');
    src: url('../fonts/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStd-Md.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStd-Md.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
@font-face {
    font-family:'HelveticaNeueLT Std Med Cn';
    src: url('../fonts/HelveticaNeueLTStd-MdCn.eot');
    src: url('../fonts/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStd-MdCn.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStd-MdCn.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStd-MdCn.svg#HelveticaNeueLTStd-MdCn') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: condensed;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'HelveticaNeueLT Std Med Ext';
    src: url('../fonts/HelveticaNeueLTStd-MdEx.eot');
    src: url('../fonts/HelveticaNeueLTStd-MdEx.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStd-MdEx.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStd-MdEx.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStd-MdEx.svg#HelveticaNeueLTStd-MdEx') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: expanded;
    unicode-range: U+0020-00FE;
}
