.btn {
  text-transform: uppercase;
  font-size: 28px;

  @extend %helvetica-cn-bold;

  &--block {
    width: 100%;
  }

  &--cart {
    &::after {
      background: transparent url("../images/cart-icon.png") no-repeat center center;
      content: '';
      display: inline-block;
      vertical-align: top;
      margin-left: 5px;
      margin-top: 3px;
      width: 32px;
      height: 27px;
    }
  }
}
