.section__hero {
  background: $yellow url("../images/hero-bg.jpg") repeat center top;
  position: relative;

  > .container {
    background: transparent url("../images/hero-bg-3.jpg") no-repeat center top;
    padding-top: 45px;
    position: relative;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        order: 2;
        margin-top: 30px;
      }
    }
  }

  .row {
    align-items: center;

    @include media-breakpoint-down(md) {
      order: 1;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.hero {
  &__content {
    @include media-breakpoint-up(lg) {
      margin-left: 41.6666666667%;
    }
  }

  &__title-intro,
  &__title,
  &__description {
    color: $black;
  }

  &__title-intro {
    font-size: 43px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;

    @include media-breakpoint-down(lg) {
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  &__title {
    font-size: 100px;
    text-transform: uppercase;
    margin: 0 0 60px;
    line-height: 0.9;

    @include media-breakpoint-down(lg) {
      font-size: 80px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 50px;
      margin-bottom: 30px;
    }

    @extend %helvetica-cn-bold;

    &--secondary {
      font-size: 78px;

      @include media-breakpoint-down(lg) {
        font-size: 62px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 39px;
      }
    }
  }

  &__description {
    font-size: 36px;

    @include media-breakpoint-up(xl) {
      padding-left: 48px;
      padding-right: 48px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      padding-left: 50px;
      padding-right: 50px;
    }

    @include media-breakpoint-only(lg) {
      padding-left: 73px;
      padding-right: 73px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      padding: 0;
    }

    @extend %helvetica-cn-bold;
  }
}
