.section__soviet-ads {
  @include media-breakpoint-down(lg) {
    background: transparent url("../images/woman-driving.jpg") no-repeat center top;
    background-size: cover;
  }

  @include media-breakpoint-only(md) {
    background-position: 100% top;
  }

  @include media-breakpoint-down(sm) {
    background-position: right top;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.soviet-ads {
  &__container {
    color: $white;
    font-size: 27px;

    @include media-breakpoint-up(xl) {
      background: transparent url("../images/woman-driving.jpg") no-repeat center top;
      background-size: cover;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @extend %helvetica-cn-bold;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    @include media-breakpoint-down(lg) {
      text-shadow: 1px 1px 1px #000000;
    }

    @include media-breakpoint-only(lg) {
      padding-right: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
